var onBeforeunloadHandler = function(e) {
	e.returnValue = 'まだ完了してませんが、サイトから離れますか？';
};
var tapCallback = function(){ 
	tap.countUP();
}
var viewGL;
var resultGL;
var makeGL;
var making = {
	page_token: "",
	guestToken: "",
	ringThickness: 0,
	ringWidth: 0,
	ringJsonName: "",
	forge: {
		Forges: []
	},
	config: "",
	ringModel: "",
	content: "",
	init: function(){
		var glCanvas = document.querySelector('#glCanvas');
		
		/* 復帰機能付き案
			function DrawForgeMode(glCanvas:HTMLCanvasElement, config:IConfig, ringModel:IRing3DModel, forgeLog:IForgeLog)
			↓

			function DrawForgeMode(glCanvas:HTMLCanvasElement, config:IConfig, ringModel:IRing3DModel, forgeLog:IForgeLog, forgeBeforeCrash:IPersonalForgeLog)
		*/
		//makeGL = OurShapeRingGL.DrawForgeMode(glCanvas, making.config, making.ringModel, making.forge, tapCallback);
		let myForge = localStorage.getItem("myHits");
		let forgeBeforeCrash = JSON.parse(myForge);
		if(!forgeBeforeCrash){
			
		}else{
			tap.count = forgeBeforeCrash.Hits.length + 1;
			$(".count .current").html(  tap.count );
			making.forge.Forges.push( JSON.parse(myForge) );
		}
		
		makeGL = OurShapeRingGL.DrawForgeMode(glCanvas, making.config, making.ringModel, making.forge, forgeBeforeCrash);
		makeGL.RegisterHitHammerCallback(tapCallback);
		making.nav();
		$(window).on('beforeunload', onBeforeunloadHandler);
	},
	drawStaticGL: function(){
		var glCanvas = document.querySelector('#glViewCanvas');
		console.log(making.config, making.ringModel, making.forge)
		OurShapeRingGL.DrawViewMode(glCanvas, making.config, making.ringModel, making.forge, true);
		//OurShapeRingGL.SetRingScaleSlider(parseFloat( .8 ))
	},
	drawStaticGLEnd: function(){
		let glCanvas = document.querySelector('#glViewCanvas2');
		resultGL = OurShapeRingGL.DrawViewMode(glCanvas, making.config, making.ringModel, making.forge, true);
		//console.log("show current ring:",making.config, making.ringModel, making.forge)
		//OurShapeRingGL.DrawViewMode(glCanvas, making.config, making.ringModel, making.forge, true);
		//OurShapeRingGL.SetRingScaleSlider(parseFloat( .8 ))
	},
	nav: function(){
		$("#btn_finish_making").off().on("click", function(){
			var result = makeGL.FinishForge();
			$(".block_message .tap_count .num").html( tap.count );
			
			setTimeout(function(){
				//making.content = JSON.stringify( result );
				//ローカルストレージから復元
				making.content = JSON.stringify( result )
				//console.log("追加前:", making.forge.Forges);
				making.forge.Forges.push( JSON.parse(making.content) );
				making.showConfirm(1);
			}, 500);
		});
	},
	showConfirm: function(num){
		$("#modal_confirm").removeClass();
		$("#modal_confirm").addClass("active" + num);
		$html.classList.add("confirm");
		setTimeout(function(){
			$html.classList.add("show_confirm");
			$("#modal_confirm a.btn_back").off().on("click", function(){
				making.closeConfirm();
			});
			$("#modal_confirm a.btn_close").off().on("click", function(){
				making.closeConfirm();
			});
			$("a.btn_confirm" + num).off().on("click", function(){
				if(num === 1){
					$("#message").addClass("active");
					$html.classList.remove("making");
					$html.classList.remove("show_confirm");
					setTimeout(function(){
						$html.classList.remove("confirm");
						making.drawStaticGL();
						FONTPLUS.reload();
						message.init();
						Cookies.set('finish_making', 1, {
							expires: 7,
							domain: location.domainname
						});
						makeGL.Dispose();
					}, 600);
				}else if(num === 2){
					message.sendResult();
				}
			});
		}, 50);
	},
	closeConfirm: function(){
		$html.classList.remove("show_confirm");
		setTimeout(function(){
			$html.classList.remove("confirm");
		}, 600);
	},
	getGuestInfo: function(){
		
	}
}
var tap = {
	count: 0,
	countUP: function(){
		tap.count++;
		$(".count .current").html(  tap.count );

		//ここで一回ずつ情報をおくる
		let forge = makeGL.FinishForge();
		var forgetJson = JSON.stringify(forge);
		localStorage.setItem('myHits', forgetJson);
		//POSTする
		if( $("#tutorial").is(":visible") ){
			let $tutorial = $("#tutorial");
			$tutorial.fadeOut(200, function(){
				$tutorial.remove();
			});
		}
	}
}