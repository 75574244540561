var original = {
	init: function(){
		FONTPLUS.attachCompleteEvent(original.setFont);
		let param 	= location.search.substring(1),
			pair 	= param.split('&');

		if(param === ""){
			//alert("パラメータがありません")
		}else{
			for(var i = 0; pair[i]; i++) {
				var kv = pair[i].split('=');
				if(kv[0] === "token"){
					making.page_token = kv[1];
				}
			}
		}
		let apiURL = `${domain}/original_pages/${making.page_token}.json`;
		let hasMyInfo = false;
		load.getJson(apiURL, {}, {}).done(function(json){
			//クッキーに自分のゲストトークンが入っていたら問い合わせる
			let myToken = Cookies.get("my_token");
			let isFinish = Cookies.get("finish_making");
			if(!myToken){
				getGLInfo();
			}else{
				//自分がすでに叩いていたら復帰する
				hasMyInfo = true;
				making.guestToken = myToken;
				let guestAPIURL =  `${domain}/original_pages/${json.original_page_token}/guests/${myToken}.json`;
				load.getJson(guestAPIURL, {}, {}).done(function(data){
					original.setMe(data);
					getGLInfo();
				});
			}
			function getGLInfo(){
				let endDate = json.finished_at*1000;
				let today = new Date();
				let end = new Date(endDate).getTime();
				//console.log(end,today.getTime() );
				if(today > end){
					let url = `/complete/?token=${making.page_token}`;
					location.href = url;
				}
				ringSetting = json;
				original.makePage();
				making.ringThickness 	= ringSetting.ring_thickness.label.replace("mm", "");
				making.ringWidth 		= ringSetting.ring_width.label.replace("mm", "");
				//console.log(making)
				making.ringJsonName 	= "Ring_" + making.ringWidth + "x" + making.ringThickness + ".json";
				//console.log(making.ringJsonName)
				$.getJSON("/gl/" + making.ringJsonName, function(ringModel) {
					$.getJSON("/gl/config.json", function(config){
						making.config = config;
						making.ringModel = ringModel;
						
						console.log("finished making:", isFinish);
						if(hasMyInfo){
							if(isFinish === "1"){
								let myForge = localStorage.getItem("myHits");
								let forgeBeforeCrash = JSON.parse(myForge);
								if(!forgeBeforeCrash){
									
								}else{
									tap.count = forgeBeforeCrash.Hits.length + 1;
									$(".tap_count .num").html(  tap.count );
									making.forge.Forges.push( JSON.parse(myForge) );
								}
								$html.classList.add("hide_other");
								$("#message").addClass("active");
								setTimeout(function(){
									making.drawStaticGL();
									FONTPLUS.reload();
									message.init();
									//makeGL.Dispose();
								}, 600);
							}else{
								original.goToMake();
							}
						}else{
						}
						//console.log(making.config, making.ringModel);
					});
				});
			}
		});
	},
	makePage: function(){
		//catch
		$(".block_main h2").html(ringSetting.purpose_type.catchphrase);
		$(".block_main .lead p").html(ringSetting.purpose_type.sub_catchphrase);

		//image
		let mainImg = ringSetting.main_image;
		$(".photo_main .wrap_img figure").css({
			"background-image": `url(${mainImg.url})`,
			"transform": `scale(${mainImg.scale})`,
			"background-position": `${mainImg.x}% ${mainImg.y}%`
		});
		$(".upload_img").addClass("flame" + mainImg.frame);
		//OWNER名入力
		let owners = ringSetting.owners;
		let colorCls = "c";
		/*
			1: ソロ
			2: ペア/カップル
			3: ペア/結婚
			4: ペア/親友
			5: ペア/記念
			6: チーム
		*/
		let purpose = ringSetting.purpose_type.id;
		let names = "";
		if(purpose === 1){
			names += `<span id="owner1">${owners[0].name}</span>さんにメッセージを送ろう！`;
			colorCls = "c" + owners[0].color + "-" + owners[0].color;
		}else if(purpose === 6){
			names += `<span id="owner1">${owners[0].name}</span>さんにメッセージを送ろう！`;
			for(var i = 0; i < 2; i++){
				colorCls += owners[0].color;
				if(i === 0 && owners.length > 1){
					colorCls += "-";
				}
			}
		}else{
			for(var i = 0; i < owners.length; i++){
				$("#owners" + i).html(owners[i].name);
				if(i === 0){
					names += `<span id="owner0">${owners[i].name}</span>さんと`;
				}else{
					names += `<span id="owner1">${owners[i].name}</span>さんにメッセージを送ろう！`;
				}
				colorCls += owners[i].color;
				if(i === 0 && owners.length > 1){
					colorCls += "-";
				}
			}
		}
		$("#message_content .lead p").html(names);
		
		$html.classList.add(colorCls);
		original.getGuests();
		original.nav();
		FONTPLUS.reload();
		setTimeout(function(){
			$("#original").removeClass("disable");
			common.scrl();
		});
	},
	nav: function(){
		$("h1 a").off().on("click", function(){
			location.href = "/original/?token=" + making.page_token;
		});
		$("a.btn_current_ring").off().on("click", function(){
			if( !$("#current_ring").hasClass("active") ){
				original.changePage("current_ring");
				/*
				if( $html.classList.contains("menu") ){
					$html.classList.remove("menu");
				}
				*/
			}
		});
		//リング制作に参加する
		$(".btn_join").off().on("click", function(){
			if( !$("#guest_setting").hasClass("active") ){
				original.changePage("guest_setting");
				if( $html.classList.contains("menu") ){
					$html.classList.remove("menu");
				}
			}
		});
		$("#current_ring a.btn_back").off().on("click", function(){
			original.changePage("main");
		});
		$("#guest_setting a.btn_back").off().on("click", function(){
			original.changePage("main");
		});
		var mailaddress = "ourshapering@jamhomemade.co.jp",
			subject 	= "私たちのカタチ-指輪-：制作期間の問合せ",
			body 		= [
				'注文コード（必須）： （購入時に発行された注文コードを記載して下さい）',
				'終了日　　（必須）： （ご希望の日付を記載して下さい）',
				'種別　　　（任意）： 前倒し終了 or 期間延長　（どちらか1つを選択して下さい）',
				'お名前　　（任意）：',
				'お電話番号（任意）：'
			];
		var lineFeed = "\r\n";
		let bodycontent = body.join(encodeURIComponent(lineFeed));
		$("a.btn_mail").off().on("click", function(){
			location.href = `mailto: ${mailaddress}?subject=${subject}&body=${bodycontent}`;
		});
	},
	changePage: function(id){
		var defer 	= $.Deferred();
		if( !$html.classList.contains("disable_blocks") ){
			$html.classList.add("disable_blocks");
			setTimeout(function(){
				common.scrollToPosition(0,10);
				$(".block").removeClass("active");
				
				if(id === "guest_setting"){
					$html.classList.add("nofooter");
					$("#" + id).addClass("active");
					original.guestSetting();
					defer.resolve();
				}else if(id === "start_making"){
					$("#" + id).addClass("active");
					$html.classList.remove("show_confirm");
					setTimeout(function(){
						$html.classList.remove("confirm");
						original.startMaking();
						defer.resolve();
					}, 600);
				}else{
					$html.classList.remove("nofooter");
					$html.classList.remove("making");
					$("#" + id).addClass("active");
					defer.resolve();
				}
				setTimeout(function(){
					$html.classList.remove("disable_blocks");
					if(id === "current_ring"){
						setTimeout(function(){
							making.drawStaticGLEnd();
						},600);
					}
				}, 100);
			},600);
		}
		return defer.promise();
	},
	getGuests: function(){
		let guests = ringSetting.guests;
		let guestHTML = '';
		let Forges = [];
		if(guests.length === 0){
			//ゲストが誰もいない時
			$(".list_joining .scroll_content").addClass("show_noguest");
		}else{
			let f = {
				Hits: [],
				user_id: 0
			}
			for(var i = 0; i < guests.length; i++){
				let g = guests[guests.length - 1 - i];
				let forge;
				if(g.processing.content === ""){
					forge = "";
				}else{
					forge = JSON.parse(g.processing.content);
				}
				let _f = object(f);
				_f.Hits = forge.Hits;
				_f.user_id = g.id;
				//forge.user_id = g.id;
				if(forge != ""){
					Forges.push(forge);
				}
				guestHTML += `<li>
								<div class="img">
									<figure style="background-image: url(${g.image_url})"></figure>
								</div>
							</li>`;
			}
			$(".list_joining .scroll_content ul").html(guestHTML);
		}
		making.forge.Forges = Forges;
		//console.log(making.forge);
	},
	guestSetting: function(){
		var fills = [0,0];
		let guestName = "";
		let guestImage = "";
		$("#guest_name").off().on("change", function(){
			let val = $(this).val();
			if(val != ""){
				fills[0] = 1;
				guestName = val;
			}else{
				fills[0] = 0;
			}
			val = removeEmoji(val);
			//console.log(val);
			$("#guest_name").val(val);
			check();
		});

		$("#icon_upload").off().on("change", function(e){
			let file = $(this).prop('files')[0];
			var reader = new FileReader();
			reader.onload = function() {
				$('.img_icon figure').css({
					"background-image": `url(${reader.result})`
				});
				$(".block_message .me figure").css({
					"background-image": `url(${reader.result})`
				});
				guestImage = reader.result;
				setTimeout(function(){
					$("#guest_setting .wrap_img").addClass("active");
					fills[1] = 1;
					$("#img_upload").val("");
					check();
				}, 50);
			}
			reader.readAsDataURL(file);
		});
		function removeEmoji(t) {
			var ranges = [
				'\ud83c[\udf00-\udfff]',
				'\ud83d[\udc00-\ude4f]',
				'\ud83d[\ude80-\udeff]',
				'\ud7c9[\ude00-\udeff]',
				'[\u2600-\u26FF]',    // Unicodeのその他の記号(Miscellaneous Symbols)
				'[\u0530-\u058F]',    // Unicodeのアルメニア文字(Armenian)
				'[\u0A00-\u0A7F]',    // Unicodeのグルムキー文字(Gurmukhi)
				'[\uD800-\uDB7F]',    // 上位サロゲート（ファイルなし）(High Surrogates)
				'[\uDB80-\uDBFF]',    // 上位私用サロゲート（ファイルなし）(High Private Use Surrogates)
				'[\uDC00-\uDFFF]',    // 下位サロゲート（ファイルなし）(Low Surrogates)
				'[\uE000-\uF8FF]',    // 私用領域（ファイルなし）(Private Use Area)
				'[¥u{EFF80}-¥u{EFFFF}]',  // 未割り当て（第14面）(Unassigned)
				'[¥u{F0000}-¥u{FFFFF}]',  // 補助私用領域A - 未割り当て（第15面）(Supplementary Private Use Area-A)
				'[¥u{100000}-¥u{10FFFF}]'
			];
			var reg = new RegExp(ranges.join('|'), 'g');
			let txt = t.replace(reg, '');
			return txt;
		}
		function check(){
			if( fills.indexOf(0) !== -1){
				$("#guest_setting .wrap_btn").addClass("disable");
				$("#btn_finish_guest_setting").off().on("click", function(){
					$("#modal_confirm").removeClass();
					$("#modal_confirm").addClass("active0");
					$html.classList.add("confirm");
					setTimeout(function(){
						$html.classList.add("show_confirm");
						$("#modal_confirm a.btn_back").off().on("click", function(){
							making.closeConfirm();
						});
						$("#modal_confirm a.btn_close").off().on("click", function(){
							making.closeConfirm();
						});
						$("a.btn_confirm0").off().on("click", function(){
							registerGuest();
						});
					}, 50);
				});
			}else{
				//クリア
				$("#guest_setting .wrap_btn").removeClass("disable");
			}
		}
		function registerGuest(){
			let guestRegisterURL = `${domain}/original_pages/${making.page_token}/guests.json`;
			load.postJson(guestRegisterURL, {
				"name": guestName,
				"image": guestImage
			}, {}).done(function(json){
				//console.log(json);
				making.guestToken = json.guest_token;
				Cookies.set('my_token', making.guestToken, {
					expires: 7,
					domain: location.domainname
				});
				$(".block_message dd .myname").html(guestName);
				original.changePage("start_making");
			});
		}
	},
	startMaking: function(){
		//console.log("start making");
		$(".wrap_btn a#btn_start_making").off().on("click", function(){
			$html.classList.add("making");
			setTimeout(function(){
				$html.classList.add("hide_other");
				making.init();
			},600);
		});
	},
	setMe: function(myInfo){
		$(".block_message .me figure").css({
			"background-image": `url(${myInfo.image_url})`
		});
		$(".block_message dd .myname").html(myInfo.name);
	},
	goToMake: function(){
		$html.classList.add("making");
		setTimeout(function(){
			$html.classList.add("hide_other");
			making.init();
		},600);
	}
}

