let uImg = {
	w: 0,
	h: 0,
	scale: 1,
	pos: [0, 0],
	flame: 0,
	selectedColor: ["F07B7B", "F07B7B"],
	size: []
}
var uploadImg = {
	img: "",
	init: function(){
		selectColor.init();
		$("#img_upload").off().on("change", function(e){
			let file = $(this).prop('files')[0];
			//console.log(file)
			var reader = new FileReader();
			reader.onload = function() {
				const img = new Image();
				img.src = reader.result;
				img.onload = function(){
					uImg.size[0] = img.naturalWidth;
					uImg.size[1] = img.naturalHeight;
					uploadImg.openImageModal(reader)
				}
				
			}
			reader.readAsDataURL(file);
		});
	},
	openImageModal: function(reader){
		$html.classList.add("img_modal");
		setTimeout(function(){
			$html.classList.add("show_img_modal");
			$("#img_upload").val("");
		}, 50);
		var img_src = $('<img>').attr('src', reader.result);
		$('#i_uploaded figure').html(img_src);
		uploadImg.img = reader.result;
		uploadImg.nav();
	},
	nav: function(){
		//
		$("#modal_uploaded a.btn_back").off("click").on("click", function(){
			$html.classList.remove("show_img_modal");
			setTimeout(function(){
				$html.classList.remove("img_modal");
				$("#img_upload").val("");
			}, 600);
		});
		/*
		TODO
		- スケール変更した時にもポジションを変えられるようにする
		*/
		//画像の拡大縮小移動
		let currentScale = 1;
		/*
		$(document).on("keyup", function(e){
			let code = e.keyCode;
			console.log(code)
			if(code === 38){
				uImg.scale += .1
			}else if(code === 40){
				uImg.scale -= .1;
			}
			if(uImg.scale < 1){
				uImg.scale = 1;
			}
			$("#i_uploaded figure img").css({
				"transform": `scale(${uImg.scale}) translate(${uImg.pos[0]}px, ${uImg.pos[1]}px)`
			});
		});
		*/
		let wrapW, wrapH;
		interact('#i_uploaded figure').gesturable({
			onstart: function(event){
				//console.log(event.scale)
			},
			onmove: function(event){
				event.preventDefault();
				uImg.scale = event.scale;
				if(uImg.scale < 1){
					uImg.scale = 1;
				}else if(uImg.scale > 2){
					uImg.scale = 2;
				}
				$("#i_uploaded figure img").css({
					"transform": `scale(${uImg.scale}) translate(${uImg.pos[0]}px, ${uImg.pos[1]}px)`
				});
			}
		}).draggable({
			listeners: {
				start(event){
					wrapW = $("#i_uploaded").width();
					wrapH = $("#i_uploaded").height();
				},
				move (event) {
					let imgW 	= Math.floor(wrapW);
					let imgH 	= (wrapW*uImg.size[1]/uImg.size[0]);

					//uImg.w 		= uImg.size[0];
					//uImg.h 		= uImg.size[1];
					let diffX, limitX;
					let diffY, limitY;
					
					diffX 	= (imgW*uImg.scale - wrapW);
					diffY 	= (imgH*uImg.scale - wrapH);
					limitX 	= diffX/uImg.scale;
					limitY 	= diffY/uImg.scale;
					
					uImg.pos[0] += event.dx;
					uImg.pos[1] += event.dy;
					//console.log(imgW, _w, diffX, uImg.pos[0])

					if(uImg.pos[0] > 0){
						uImg.pos[0] = 0;
					}else if(uImg.pos[0] < -limitX){
						uImg.pos[0] = -limitX;
					}
					if(uImg.pos[1] > 0){
						uImg.pos[1] = 0;
					}else if(uImg.pos[1] < -limitY){
						uImg.pos[1] = -limitY;
					}
					let txtdebug = `${uImg.pos[0]} / ${uImg.pos[1]} <br /> ${limitX} / ${limitY} <br /> ${uImg.scale}`;

					$("#debug p").html(txtdebug);
					$("#i_uploaded figure img").css({
						"transform": `scale(${uImg.scale}) translate(${uImg.pos[0]}px, ${uImg.pos[1]}px)`
					});
				},
			}
		});

		//フレーム選択
		$("#select_flame li a").off().on("click", function(){
			let idx = $("#select_flame li a").index(this);
			uImg.flame = idx;
			let $this = $(this);
			if( !$this.hasClass("active") ){
				$("#select_flame li a").removeClass("active");
				$this.addClass("active");
				$("#i_uploaded").removeClass();
				$("#i_uploaded").addClass("flame" + idx);
				$("#upload_img").removeClass(function(index, className) {
					return (className.match(/\bflame\S+/g) || []).join(' ');
				});
				$("#upload_img").addClass("flame" + idx);
			}
		});
		$("#btn_selected_frame").off().on("click", function(){
			//$(".upload_img img").attr("src", uploadImg.img);
			//let posX = ( uImg.pos[0] / ( uImg.size[0]*uImg.scale) )*100;
			//let posY = ( uImg.pos[1] / ( uImg.size[0]*uImg.scale) )*100;
			let imgw 	= $("#step01 .photo_main .img").width();
			let imgh 	= $("#step01 .photo_main .img").width()*uImg.size[1]/uImg.size[0];
		
			let ratio = imgw / $("#i_uploaded").width();
			let _posX = uImg.pos[0]*ratio;
			let _posY = uImg.pos[1]*ratio;
			let posX = 0;
			if(_posX != 0){
				posX 	= _posX / ( imgw - imgw*uImg.scale )*100;
			}
			let bgWrapH = $("#step01 .photo_main .img").height();
			let posY 	= _posY / ( bgWrapH - imgh )*100;
			console.log(posX);
			if(posX == null){
				posX = 0;
			}
			console.log(_posX, posX, imgw, uImg.scale);
			$(".upload_img figure").css({
				"background-image": `url(${uploadImg.img})`,
				"background-position": `${posX}% ${posY}%`,
				"transform": `scale(${uImg.scale})`
			});

			$("#upload_img").addClass("flame" + uImg.flame);
			let imageRegisterurl = `${domain}/order_entries/${setting.orderCode}/main_image.json`;
			let imgRegisterParams = {
				order_code: setting.orderCode,
				content: uploadImg.img,
				frame: uImg.flame,
				scale: uImg.scale,
				x: posX,
				y: posY
			}
			load.postJson(imageRegisterurl, imgRegisterParams).done(function(json){
				console.log("画像登録完了:", json);
				$html.classList.remove("show_img_modal");
				setTimeout(function(){
					$html.classList.remove("img_modal");
				}, 600);
			});
			
		});

		//オリジナルページ作成
		$(".btn_make_originalpage").off().on("click", function(){
			$html.classList.add("confirm");
			setTimeout(function(){
				$html.classList.add("show_confirm");
				$("#modal_confirm a.btn_confirm").off().on("click", function(){
					let generateURL = `${domain}/order_entries/${setting.orderCode}/original_page.json`;
					load.postJson(generateURL, {}).done(function(json){
						$html.classList.remove("show_confirm");
						$("#upload_photo").removeClass("active");
						$("#upload_photo").addClass("disable");
						setTimeout(function(){
							$("#block_complete").addClass("active");
							$html.classList.remove("confirm");

							//completepage情報入れ込み
							let url = `https://ourshapering.jamhomemadeonlineshop.com/original/?token=${mySetting.original_page_token}`;
							$("#block_complete .block_copyurl input").val(url);
							let originalpage_url = "/original/?token=" + mySetting.original_page_token;
							$("#block_complete a.btn_link_originalpage").attr("href", originalpage_url);
							setTimeout(function(){
								$("#upload_photo").addClass("end");
								$("#block_complete").addClass("show");
								common.nav();
							},100);
						}, 600);
						//console.log("オリジナルページ作成:", json);
						//get ORIGINAL PAGE
						/*
						
						*/
					});
				});
				$("#modal_confirm a.btn_back").off().on("click", function(){
					$html.classList.remove("show_confirm");
					setTimeout(function(){
						$html.classList.remove("confirm");
					}, 600);
				});
				$("#modal_confirm a.btn_close").off().on("click", function(){
					$html.classList.remove("show_confirm");
					setTimeout(function(){
						$html.classList.remove("confirm");
					}, 600);
				});
			}, 50);
			
		});
	}
}
var colorCurrentPage = [0,0];
var selectColor = {
	init: function(){
		this.nav();
		let len = $("#step02 .select_color").length;
		let count = 0;
		addColor(count);

		function addColor(count){
			let ownerID = mySetting.owners[count].id;
			let colorParam = {
				order_code: setting.orderCode,
				owner_id: ownerID,
				content: uImg.selectedColor[count]
			}
			let colorURL = `${domain}/order_entries/${setting.orderCode}/owners/${ownerID}/color.json`;
			load.postJson(colorURL, colorParam).done(function(json){
				if(count < len-1){
					count++;
					addColor(count);
				}
			});
		}
	},
	nav: function(){
		$("a.btn_next").off().on("click", function(){
			let idx = $(this).attr("data-index");
			colorCurrentPage[idx]++;
			$(".slide_colors").eq(idx).removeClass(function(index, className) {
				return (className.match(/\bactive\S+/g) || []).join(' ');
			});
			$(".slide_colors").eq(idx).addClass("active" + colorCurrentPage[idx]);
		});
		$("a.btn_prev").off().on("click", function(){
			let idx = $(this).attr("data-index");
			colorCurrentPage[idx]--;
			$(".slide_colors").eq(idx).removeClass(function(index, className) {
				return (className.match(/\bactive\S+/g) || []).join(' ');
			});
			$(".slide_colors").eq(idx).addClass("active" + colorCurrentPage[idx]);
		});
		$(".color_list").each(function(i){
			$(this).find("li a").off().on("click", function(){
				$(".color_list").eq(i).find("li a").removeClass("active");
				$(this).addClass("active");
				uImg.selectedColor[i] = $(this).attr("data-color");

				let ownerID = mySetting.owners[i].id;
				let colorParam = {
					order_code: setting.orderCode,
					owner_id: ownerID,
					content: uImg.selectedColor[i]
				}
				let colorURL = `${domain}/order_entries/${setting.orderCode}/owners/${ownerID}/color.json`;
				load.postJson(colorURL, colorParam).done(function(json){
					//console.log(ownerID + "のカラー登録", json)
				});
			});
		});
	}
}