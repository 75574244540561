var message = {
	content: "",
	init: function(){
		let val = "";
		$("#area_message").off().on("input", function(){
			val = $(this).val();
			if(val != ""){
				$(".block_message .wrap_btn").removeClass("disable");
			}else{
				$(".block_message .wrap_btn").addClass("disable");
			}
		});
		$(".btn_confirm_message").off().on("click", function(){
			message.content = val;
			making.showConfirm(2);
		});
	},
	sendResult: function(){
		let registerURL = `${domain}/original_pages/${making.page_token}/guests/${making.guestToken}/processing.json`;
		let result 		= localStorage.getItem("myHits");
		making.content 	= result;
		//console.log("追加前:", making.forge.Forges);
		//making.forge.Forges.push( JSON.parse(making.content) );
		let params = {
			original_page_token	: making.page_token,
			guest_token			: making.guestToken,
			message 			: message.content,
			content				: making.content,
			tap_count			: tap.count
		}
		console.log(params);
		load.postJson(registerURL, params, {}).done(function(json){
			console.log(json);
			Cookies.remove('my_token');
			Cookies.remove('finish_making');
			localStorage.removeItem("myHits");
			making.closeConfirm();
			$html.classList.add("end_making");
			$html.classList.remove("hide_other");
			//return false
			setTimeout(function(){
				$html.classList.remove("nofooter");
				setTimeout(function(){
					location.reload();
					/*
					$("#start_making").removeClass("active");
					$("#main").addClass("active");
					//making.drawStaticGLEnd();
					$html.classList.remove("option");
					setTimeout(function(){
						$html.classList.remove("end_making");
					}, 50);
					*/
				}, 1000);
			}, 50);
			
		});
	}
}