const 	ua 		= window.navigator.userAgent.toLowerCase(),
		appver 		= window.navigator.appVersion.toLowerCase();
let isLegacy 	= appver.indexOf("msie 8.0") > -1 || appver.indexOf("msie 9.0") > -1 || appver.indexOf("msie 10.0") > -1,
	isIE 		= ua.indexOf("msie") !== -1 || navigator.userAgent.match(/Trident/),
	isIE11 		= navigator.userAgent.toLowerCase().indexOf('trident/7') > -1,
	isAndroid 	= navigator.userAgent.match(/Android/i),
	isIPhone 	= navigator.userAgent.match(/iPhone/i),
	isSP 		= navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i),
	isIPad 		= navigator.userAgent.match(/iPad/i),
	isFF 		= ua.indexOf("firefox")!== -1,
	isEdge 		= ua.indexOf("applewebkit") > -1 && ua.indexOf("edge") > -1,
	isMobile	= true,
	isSmallScreen = false,
	isPC = true;

const ORIGINALPAGE = "https://ourshapering.jamhomemadeonlineshop.com/original/";

if(isAndroid || isIPhone || isIPad){
	isPC = false;
}else{
	isPC = true;
}
var $html = document.documentElement;
//win or mac
if( navigator.userAgent.indexOf("Win") !== -1 ){
	let ary = ["os_windows"];
	if(isFF){
		ary.push("firefox");
	}else{
		if(isIE){
			if(isIE11){
				ary.push("ie11");
			}
		}else if(isEdge){
			ary.push("edge");
		}else{
		}
	}
	addClassToBody(ary);
}
if(navigator.userAgent.indexOf("Mac") !== -1 ){
	let ary = ["os_mac"];
	if(isFF){
		ary.push("firefox");
	}else{
		if (ua.indexOf('safari') > -1 && ua.indexOf('chrome') === -1){
			ary.push("safari");
		}else{
			ary = ["os_mac"];
		}
	}
	addClassToBody(ary);
}
function addClassToBody(ary){
	for(let i = 0; i < ary.length; i++){
		$html.classList.add(ary[i]);
	}
}
function object(o) {
	var f = object.f, i, len, n, prop;
	f.prototype = o;
	n = new f;
	for (i=1, len=arguments.length; i<len; ++i){
		for (prop in arguments[i]){
			n[prop] = arguments[i][prop];
		}
	}
	return n;
}
object.f = function(){};

//console.log(location.hostname);
let domain = "";
if(location.hostname === "ourshapering.jamhomemadeonlineshop.com"){
	domain = "https://api.ourshapering.jamhomemadeonlineshop.com";
}else{
	domain = "https://stg-api.ourshapering.jamhomemadeonlineshop.com";
}
//let domain = "https://stg-api.ourshapering.jamhomemadeonlineshop.com";

var ringSetting = {};
var load = {
	getJson: function(url,params, errorText){
		var defer 	= $.Deferred();
		var url 	= url;
		$.ajax({
			type: "GET",
			dataType: 'json',
			url: url,
			data: params,
			success	: defer.resolve,
			error: function(){
				alert(errorText);
				defer.reject
			}
		});
		return defer.promise();
	},
	postJson: function(url, params, headers){
		if(!headers){
			var headers = {}
		}
		var defer 	= $.Deferred();
		var url 	= url;
		$.ajax({
			type: "POST",
			dataType: 'json',
			url: url,
			data: params,
			header: headers,
			success	: defer.resolve,
			error: defer.reject
		});
		return defer.promise();
	}
}