var mySetting = {}
var setting = {
	currentPage: 0,
	maxPage: 4,
	orderCode: "",
	init: function(){
		setTimeout(function(){
			$("#loading").addClass("disable");
			setTimeout(function(){
				$("body").removeClass("disable");
				setTimeout(function(){
					$("#block_start").addClass("show_content");
					$("#loading").remove();
				}, 500);
			}, 1200);
		}, 2500);
		setting.nav();
	},
	nav: function(){
		$(".btn_start").off().on("click", function(){
			$("#block_start").removeClass("show_content");
			$("#block_start").addClass("disable");
			setTimeout(function(){
				$("#block_start").addClass("end");
				$("#block_code").addClass("active");
				setTimeout(function(){
					$("#block_code").addClass("show");
				}, 100);
			}, 1000);
		});
		$("#my_code").off().on("change", function(){
			let val = $(this).val();
			if(val != ""){
				$("#block_code .btn_wrap").removeClass("disable");
				$("#check_code").off().on("click", function(){
					let url = `${domain}/order_entries/${val}.json`;
					load.getJson(url, {}, "注文コードが正しくありません。入力した注文コードを確認してください。").done(function(json){
						mySetting = json;
						setting.orderCode = val;
						if(json.original_page_generated){
							let url = "/original/?token=" + json.original_page_token;
							location.href = url;
						}
						//エラーの場合分け必須
						if(!json){

						}else{
							setting.showUpload();
						}
					});
				});
			}else{
				$("#block_code .btn_wrap").addClass("disable");
			}
		});

	},
	showUpload: function(){
		//console.log(mySetting, "open");
		//console.log("TYPE:", mySetting.purpose_type , mySetting.purpose_type.id)
		/*
			1: ソロ
			2: ペア/カップル
			3: ペア/結婚
			4: ペア/親友
			5: ペア/記念
			6: チーム
		*/
		let owners = mySetting.owners;
		if(mySetting.purpose_type.id === 1 || mySetting.purpose_type.id === 6){
			$("#owner1").html(mySetting.owners[0].name);
			$(".select_color").eq(1).remove();
		}else{
			$("#owner1").html(mySetting.owners[0].name);
			$("#owner2").html(mySetting.owners[1].name);
		}
		$("#block_code").addClass("disable");
		setTimeout(function(){
			$("#block_code").addClass("end");
			$("#upload_photo").addClass("active");
			setTimeout(function(){
				FONTPLUS.reload();
				$("#upload_photo").addClass("show");
				uploadImg.init();
			}, 50);
		}, 1000);
	}
}