var isPage = "";
let Animate = {
	top: 0,
	show: 0
}
let animations = [];

var common = {
	init: ()=>{
		var resizeFlag;
		var resizeTimer = null;
		var $body = $("body");

		common.resize();
		common.nav();
		if( isPage === "index" ){
			setting.init();
			setTimeout(function(){
				common.activeChat();
			}, 600)
		}else if( isPage === "original" ){
			original.init();
		}else if( isPage === "complete" ){
			common.scrl();
		}
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};
		$(document).off("scroll touchmove").on("scroll touchmove", function(){
			common.scrl();
		});
		$(window).off("resize").on("resize",function(){
			if(!$body.hasClass("resize")){
				$body.addClass("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
	},
	activeChat: function(){
		widget.setState(1);
		chat.option({
			theme_color: '#{chat.theme_color}',
			offline_operator_name: '#{chat.offline_operator_name}',
			offline_title: '#{chat.offline_title}',
			offline_text: '#{chat.offline_text}',
			disable_user_send_status: "offline",
			sticky_content: {
				text: '#{chat.sticky_header_text}'
			},
			hide_launcher: true
		});
		chat.open();
		chat.send([{text: '#{chat.sticky_content_text}'}]);
		widget.method('activateChat', function(){
			// widget.setState(0);
			chat.activate();
			try{
				tracker.track("sp_embedded_chat_open",{
					custom_source: "contact_embedded_menu"
				});
			} catch(e){
				if('tracker' in window){
					tracker.track("err_sp_chat_open", {error_message: e.message});
				}
			}
		});
		chat.emitter.on('deactivate', function(){
			widget.setState(1);
			try{
				tracker.track("sp_embedded_chat_close",{
					custom_source: "contact_embedded_menu"
				});
			} catch(e){
				if('tracker' in window){
					tracker.track("err_sp_embedded_chat_close", {error_message: e.message});
				}
			}
		});
	},
	nav: ()=>{
		$("a.btn_copy").off().on("click", function(){
			var input = $(".block_copyurl input")[0];
			// 文字をすべて選択
			input.select();
			// コピー
			document.execCommand("copy");
			alert("URLをコピーしました。");
		});
		//original page
		$("a#btn_menu").off().on("click", function(){
			if( !$html.classList.contains("menu") ){
				$html.classList.add("menu");
			}else{
				$html.classList.remove("menu");
			}
		});

		$("#globalmenu nav ul li a").off().on("click", function(){
			let id = $(this).attr("data-name");
			
			if(!id){

			}else{
				if(id === "help"){

				}else{
					if( !$("#main").hasClass("active") ){
						original.changePage("main").done(function(){
							//console.log("done")
							goScroll();
						});
					}else{
						goScroll()
					}
					function goScroll(){
						let posY = $('[data-id="' + id + '"]').offset().top;
						common.scrollToPosition(posY, 1000);
						$html.classList.remove("menu");
					}
				}
			}
		});
		//share
		let $this;
		$(".list_share li a").off().on("click", function(){
			$this = $(this);
			var idx = $(".list_share li a").index(this);
			shareTxt(idx);
		});
		function shareTxt(num){
			let url = `${ORIGINALPAGE}?token=${mySetting.original_page_token}`;
			if(num === 0){
				//LINE
				var txt = $this.attr("data-text") + "\n" + url;
				window.open(
					'http://line.me/R/msg/text/?' + encodeURIComponent(txt),
					'linesharedialog',
					'width=626,height=436');
			}else if(num === 1){
				//Facebook
				window.open(
					'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url),
					'facebooksharedialog',
					'width=626,height=436');
			}else{
				//Twitter
				var txt = "";
				window.open(
					'http://twitter.com/share?url=' + encodeURIComponent(url) + "&text=" + encodeURIComponent(txt),
					'twittersharedialog',
					'width=626,height=436');
			}
		}
	},
	resize: ()=>{
		var winW = window.innerWidth;
		var winH = window.innerHeight;
		var $body = $("body");

		animations = [];
		$(".a").each(function(i){
			let _a 	= object(Animate);
			_a.top 	= Math.floor( $(this).offset().top );
			_a.show = Math.floor( _a.top + winH/3 );
			animations.push(_a);
		});
		if(isPage === "complete"){
			complete.resize();
		}

		setTimeout(function(){
			if($body.hasClass("resize")){
				$body.removeClass("resize");
			}
		},300);
	},
	scrollToPosition: (posY, duration) =>{
		var $page 	= $("html,body"),
			ease 	= "easeInOutCubic",
			flag 	= true;
		$page.animate({
			scrollTop : posY + "px"
		},{
			"duration" 	: duration,
			"easing" 	: ease,
			"complete"	: function(){
			}
		});
	},
	scrl: () =>{
		var topD = $(document).scrollTop(),
			btmD = topD + $(window).height();

		for(var i = 0; i < animations.length; i++){
			if( btmD >= animations[i].show ){
				activeAnimation(i);
			}
		}
		function activeAnimation(i){
			let $a = $(".a").eq(i);
			if( !$a.hasClass("animate") ){
				$a.addClass("animate");
			}
		}
	}
}
document.addEventListener('DOMContentLoaded', function () {
	isPage = $("body").attr("id");
	common.init();
});